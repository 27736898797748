import dynamic from 'next/dynamic'
import { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import styleConfig from '@/styles/style-config'
import { BackgroundImage } from '@/components/image'
import { styled } from 'stitches.config'

const CustomButton = dynamic(() => import('@/components/common/custom-button'))

const ContentImage = styled(BackgroundImage, {
  height: '500px',
  '@md': { height: '400px' },
  '@lg': { height: '500px' },
  '@xl': { height: '600px' },
})

const ContentTextWithImageBlock = (props) => {
  const [headline, setHeadline] = useState(props.headline)
  const desktopImage = props?.backgroundImageDesktop?.url
  const tabletImage = props?.backgroundImageTablet?.url || props?.backgroundImageDesktop?.url
  const mobileImage =
    props?.backgroundImageMobile?.url ||
    props?.backgroundImageTablet?.url ||
    props?.backgroundImageDesktop?.url
  const backgroundColor = props?.backgroundColor || 'EDEDED'
  const ctaLeftButtonType = props?.ctaLeftType
    ? props?.ctaLeftType?.split(' ')?.join('-')
    : 'primary'
  const ctaRightButtonType = props?.ctaRightType
    ? props?.ctaRightType?.split(' ')?.join('-')
    : 'primary'
  const eyebrowColor = props?.eyebrowColor || '525151'
  const headlineColor = props?.headlineColor || '000000'
  const bodyTextColor = props?.bodyTextColor || '000000'
  const buttonGap =
    !ctaLeftButtonType.includes('text') || !ctaRightButtonType.includes('text') || false

  useEffect(() => {
    let replaceString = props.headline && props.headline.replace('*', '<em>')
    if (replaceString) {
      const index = replaceString.lastIndexOf('*')
      replaceString =
        replaceString.slice(0, index) + replaceString.slice(index).replace('*', '&nbsp</em>')
      setHeadline(replaceString)
    }
  }, [props.headline])
  // console.log(props?.bodyText)
  return (
    <>
      <div className="content-text-with-image-block w-full flex flex-col">
        <div className="content-text-with-image-block__image-wrap">
          <ContentImage
            imageProps={{
              priority: true,
              quality: 85,
              objectFit: 'cover',
              srcMap: {
                mobile: mobileImage,
                tablet: tabletImage,
                desktop: desktopImage,
              },
            }}
          />
        </div>
        <div className="content-text-with-image-block__content flex flex-col flex-1">
          {props?.eyebrow && (
            <p className="content-text-with-image-block__eyebrow text-xsm lg:text-sm">
              {props.eyebrow}
            </p>
          )}
          {props?.headline && (
            <h4 className="content-text-with-image-block__headline font-primary text-md md:text-spg">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props?.headline}</ReactMarkdown>
            </h4>
          )}
          <div
            className={`content-text-with-image-block__body-text text-sm lg:text-md ${
              (props?.ctaLeftLabel && props?.ctaLeftUrl) ||
              (props?.ctaRightLabel && props?.ctaRightUrl)
                ? 'mb-10'
                : ''
            }`}
          >
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props?.bodyText}</ReactMarkdown>
          </div>
          {((props?.ctaLeftLabel && props?.ctaLeftUrl) ||
            (props?.ctaRightLabel && props?.ctaRightUrl)) && (
            <div
              className={`button-group flex flex-col md:flex-row items-start justify-start gap-x-10 mt-auto ${
                buttonGap ? 'gap-y-5' : ''
              }`}
            >
              {props?.ctaLeftLabel && props?.ctaLeftUrl && (
                <CustomButton
                  type="link"
                  label={props?.ctaLeftLabel}
                  url={props?.ctaLeftUrl}
                  size="lg"
                  variant={ctaLeftButtonType}
                />
              )}
              {props?.ctaRightLabel && props?.ctaRightUrl && (
                <CustomButton
                  type="link"
                  label={props?.ctaRightLabel}
                  url={props?.ctaRightUrl}
                  size="lg"
                  variant={ctaRightButtonType}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .content-text-with-image-block__image-wrap {
          min-height: 500px;
        }
        .content-text-with-image-block__content {
          background-color: #${backgroundColor};
          padding: 20px;
        }

        .content-text-with-image-block__eyebrow {
          color: #${eyebrowColor};
          line-height: 17px;
          margin-bottom: 13px;
        }

        .content-text-with-image-block__headline {
          color: #${headlineColor};
          letter-spacing: -0.5px;
          line-height: 18px;
          margin-bottom: 36px;
        }

        .content-text-with-image-block__body-text {
          color: #${bodyTextColor};
          line-height: 18px;
        }

        @media (min-width: ${styleConfig.theme.screens.md}) {
          .content-text-with-image-block__content {
            padding: 30px;
          }
          .content-text-with-image-block__image-wrap {
            min-height: 400px;
          }

          .content-text-with-image-block__headline {
            line-height: 20px;
          }
        }

        @media (min-width: ${styleConfig.theme.screens.lg}) {
          .content-text-with-image-block__image-wrap {
            min-height: 500px;
          }

          .content-text-with-image-block__eyebrow {
            line-height: 18px;
          }

          .content-text-with-image-block__headline {
            font-size: 28px;
            line-height: 26px;
          }

          .content-text-with-image-block__body-text {
            line-height: 20px;
          }
        }

        @media (min-width: ${styleConfig.theme.screens.xl}) {
          .content-text-with-image-block__image-wrap {
            min-height: 600px;
          }

          .content-text-with-image-block__headline {
            font-size: 32px;
            line-height: 30px;
          }
        }
      `}</style>
    </>
  )
}

export default ContentTextWithImageBlock
